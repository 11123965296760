import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Przykro mi, strona o takiej ścieżce nie istnieje.</p>
    <p>Wróć do strony głównej: </p>
    <Link to="/">Strona główna</Link>
  </Layout>
)

export default NotFoundPage
